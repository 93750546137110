import { useLayoutEffect, useState } from 'react';
import history from '@history';
import { Router } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import axios from 'axios';


function BrowserRouter({ basename, children, window }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  const [access, setAccess] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => history.listen(setState), [history]);

  useLayoutEffect(() => {

    axios.get(`/api/v1/whitelist_ip/filter${history.location.search ? history.location.search : ""}`)
      .then((res) => {
        setIsLoading(false)
        setAccess(res.data.response)
        if (history.location.search.includes("token")) {
          history.replace(history.location.pathname)
        }
      })
      .catch((err) => {
        console.log("err", err)
      })

  }, [history.location])


  if (isLoading) {
    return (
      <div className='bg-white w-[100%] h-[100vh] flex justify-center items-center' >
        <FuseLoading />
      </div>
    )
  }

  if (access === false) {
    return (
      <div className='bg-white w-[100%] h-[100vh] flex justify-center items-center' >
        <div>
          <img src='/assets/images/logo/not-auth.png' width={200} height={200} className='mx-auto' />
          <h1 className='text-4xl md:text-7xl font-extrabold tracking-tight leading-tight md:leading-none text-center'>Not Access</h1>
          <h5 className='mt-8 text-lg md:text-xl font-medium tracking-tight text-center'>Sorry you're not to see this.</h5>
        </div>
      </div>
    )
  }


  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}

export default BrowserRouter;
